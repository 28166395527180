import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deferloader',
  templateUrl: './deferloader.component.html',
  styleUrls: ['./deferloader.component.scss'],
  standalone: false
})

/**
Deferloader component utilise the deferload directive (src/app/shared/directives/deferload.directive.ts)
to update the isVisible variable. Once isVisible is set to true, that means the component(and its child components)
is visible on screen.
*/
export class DeferloaderComponent implements OnInit {
  public isVisible = false;

  constructor() {}

  ngOnInit() {}
}
