import {
  Directive,
  ElementRef,
  Renderer2,
  Inject,
  OnInit,
  ViewContainerRef,
  HostListener,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PlaceholderComponent } from '../ui-components/placeholder/placeholder.component';

@Directive({
  selector: '[placeholder]',
  standalone: false
})
export class PlaceholderDirective implements OnInit, OnDestroy, OnChanges {
  @Input()
  placeholder = '';

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document,
    private vc: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}
  ngOnChanges(): void {}

  ngOnInit() {}

  @HostListener('loading', ['$event'])
  handleLoading(event) {
    let count = 1;
    if (this.isNumber(event)) {
      count = event;
    }
    this.elementRef.nativeElement.style.display = 'none';
    const comFact =
      this.componentFactoryResolver.resolveComponentFactory(
        PlaceholderComponent
      );
    let placeholderComponent: ComponentRef<PlaceholderComponent>;
    placeholderComponent = this.vc.createComponent(comFact);
    placeholderComponent.instance.placeholder = this.placeholder;
  }

  @HostListener('loaded', ['$event'])
  handleLoaded(event) {
    this.elementRef.nativeElement.style.display = 'block';
    this.vc.detach();
  }

  ngOnDestroy() {}

  isNumber(value: string | number): boolean {
    return value != null && value !== '' && !isNaN(Number(value.toString()));
  }
}
