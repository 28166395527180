import {
  Component,
  OnInit,
  ContentChildren,
  QueryList,
  ElementRef
} from '@angular/core';
import { PageSliderPanelComponent } from '../page-slider-panel/page-slider-panel.component';

/**
 * Page slider container component is a placeholder for Page Slider Panel and is decorative display element.
 */
@Component({
  selector: 'app-page-slider-container',
  templateUrl: './page-slider-container.component.html',
  styleUrls: ['./page-slider-container.component.scss'],
  standalone: false
})
export class PageSliderContainerComponent implements OnInit {
  @ContentChildren(PageSliderPanelComponent, { read: ElementRef })
  pages: QueryList<ElementRef>;

  constructor() {}

  ngOnInit() {}
}
