import { Component, OnInit } from '@angular/core';

/**
 * Page slider panel component is a placeholder for course progress cards and is decorative display element.
 */
@Component({
  selector: 'app-page-slider-panel',
  templateUrl: './page-slider-panel.component.html',
  styleUrls: ['./page-slider-panel.component.scss'],
  standalone: false
})
export class PageSliderPanelComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
