import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-introexp-placeholder',
  templateUrl: './introexp-placeholder.component.html',
  styleUrls: ['./introexp-placeholder.component.scss'],
  standalone: false
})
export class IntroExpPlaceholderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
